<template>
  <div :class="['row justify-center', isMobile ? 'bg-white q-pt-md items-center' : 'items-center']"
       style="height: 100vh">
    <!-- login box -->
    <div
      :class="['column bg-white q-pa-md justify-between text-grey-4', isMobile ? 'full-width' : 'border-radius border-solid']"
      :style="{width: isMobile ? undefined : '360px'}">

      <!-- content -->
      <div>

        <!-- title -->
        <div :class="['row full-width justify-center items-center text-grey-8', isMobile ? 'q-mb-md' : 'q-mb-md']">

          <div v-if="page === 1">

            <!-- mobile -->
            <div v-if="isMobile" class="text-body1 text-weight-regular">
              ورود / ثبت‌نام
            </div>

            <!-- desktop -->
            <h1 v-else class="no-margin text-weight-regular">
              ورود / ثبت‌نام
            </h1>

          </div>

          <div v-else-if="page === 2">

            <!-- mobile -->
            <div v-if="isMobile" class="text-body1 text-weight-regular">
              رمز عبور
            </div>

            <!-- desktop -->
            <h1 v-else class="no-margin text-weight-regular">
              رمز عبور
            </h1>

          </div>

          <div v-else>

            <!-- mobile -->
            <div v-if="isMobile" class="text-body1 text-weight-regular">
              رمز موقت
            </div>

            <!-- desktop -->
            <h1 v-else class="no-margin text-weight-regular">
              رمز موقت
            </h1>

          </div>

        </div>

        <!-- info -->
        <div :class="['row full-width justify-center items-center', isMobile?'q-mb-md':'q-mt-xs q-mb-lg']">

          <div v-if="page === 1" class="text-caption info">
            شماره موبایل خود را وارد کنید
          </div>

          <div v-else-if="page === 2" class="text-caption info">
            رمز عبور را وارد کنید
          </div>

          <div v-else class="text-caption info">
            رمز موقت برای شماره موبایل {{ phoneNumber }} ارسال شد
          </div>

        </div>

        <!-- input -->
        <div :class="['row full-width items-center', isMobile ? 'q-mb-md' : 'q-mb-sm']">

          <q-input v-show="page === 1"
                   class="full-width text-center hover-primary"
                   v-model="phoneNumber"
                   ref="phoneNumber"
                   dir="ltr"
                   outlined
                   dense
                   color="primary"
                   inputmode="numeric"
                   @keyup.enter="validateNumber"
                   :rules="[
                     val => !!val || 'شماره موبایل را وارد کنید' ,
                     val => val.isValidPhoneNumber() || 'شماره موبایل صحیح نیست'
                     ]"
          />

          <q-input v-show="page === 2"
                   class="col"
                   :type="isPwd ? 'password' : 'text'"
                   v-model="password"
                   ref="password"
                   outlined
                   dense
                   color="grey"
                   @keyup.enter="login"
          >
            <template v-slot:append>
              <q-btn color="primary"
                     :icon="isPwd ? 'la la-eye-slash' : 'la la-eye'"
                     flat
                     dense
                     round
                     class="cursor-pointer"
                     @click="isPwd = !isPwd"
              />
            </template>
          </q-input>

          <q-input v-show="page > 2"
                   class="full-width"
                   type="text"
                   v-model="verificationCode"
                   ref="verificationCode"
                   outlined
                   dense
                   inputmode="numeric"
                   color="grey"
                   :rules="[
                     val => !!val || 'کد 5 رقمی را وارد کنید' ,
                     val => val.length===5 || 'کد 5 رقمی را وارد کنید' ]"
                   @keyup.enter="login"
          />

        </div>

        <!-- forgot password -->
        <!--        <div v-if="page === 2" :class="['row full-width items-center', isMobile?'q-mb-sm':'q-mb-sm']">-->
        <!--          <a href="" class="text-caption text-grey-7 cursor-pointer hover-primary" style="text-decoration: none">-->
        <!--            فراموشی رمز عبور-->
        <!--          </a>-->
        <!--        </div>-->

        <!-- temporary password -->
        <div v-if="page === 2" :class="['row full-width items-center', isMobile?'q-mb-md':'q-mt-xs q-mb-md']">
          <a class="text-caption text-grey-7 cursor-pointer hover-primary" @click="changePasswordType">
            ورود با رمز موقت
          </a>
        </div>

        <div v-if="page === 3"
             :class="['row full-width justify-between items-center', isMobile?'q-mb-md':'q-mt-xs q-mb-md']">

          <a :disabled="isResendDisabled" class="text-caption text-grey-8 cursor-pointer hover-primary"
             @click="resendVerificationCode">
            ارسال مجدد
          </a>

          <div :class="['text-caption text-weight-regular text-primary', isTimerHidden?'hidden':'']">
            {{ timerSeconds }} : {{ timerMinutes }}
          </div>

        </div>

        <!-- continue/login buttons -->
        <div
          :class="['row full-width items-center', isMobile ? page === 2 ? 'q-mb-md' : undefined : page === 2 ? 'q-mt-sm q-mb-md' : 'q-mt-sm']">

          <q-btn v-if="page === 1" class="full-width border-radius q-pa-xs text-weight-regular"
                 unelevated
                 color="primary"
                 label="ادامه"
                 @click="validateNumber"
          />

          <q-btn v-else class="full-width border-radius q-pa-xs text-white text-weight-regular"
                 unelevated
                 color="primary"
                 label="ورود"
                 @click="login"
                 :loading="isLoading"
          >
            <template v-slot:loading>
              <q-spinner-tail
                color="grey-4"
                size="md"
              />
            </template>
          </q-btn>

        </div>

        <!-- privacy policy -->
        <div v-if="page === 1" class="row full-width q-mt-md">
          <div class="text-caption text-weight-regular text-justify text-grey-7">
            با ورود و یا ثبت نام در حامیانه شما
            <router-link class="text-subtitle2 text-weight-light text-black" style="text-decoration: underline"
                         to="/privacy-policy" target="_blank">قوانین حریم خصوصی
            </router-link>
            آن را می پذیرید
          </div>
        </div>

        <!-- application version -->
        <div v-if="isMobile && page === 1" class="row full-width q-mt-md justify-center text-grey-6">
          <!-- version -->
          <div class="text-caption">
            نسخه {{ version }}
          </div>
        </div>

        <!-- change number button -->
        <div v-if="page === 2" class="row full-width">
          <q-btn class="full-width border-radius" color="red-1" text-color="primary"
                 label="تغییر شماره موبایل" unelevated @click="changePage"/>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import {version} from '../../package.json'
import {useMeta} from "quasar";
import * as smsRetriever from '../../src-cordova/plugins/cordova-plugin-sms-retriever/www/SMSRetriever'

export default {
  async preFetch({store, currentRoute, previousRoute, redirect, ssrContext, urlPath, publicPath}) {
    let meta = {}
    let title = store.state.tenant.title
    meta.title = `${title} | ورود به حساب کاربری`
    return store.dispatch('setMeta', meta)
  },
  name: "Login",
  data: () => ({
    version,
    page: 1,
    phoneNumber: '',
    password: '',
    verificationCode: '',
    isResendDisabled: true,
    isTimerHidden: false,
    timerSeconds: 0,
    timerMinutes: 0,
    baseUrl: process.env.API_URL,
    isLoading: false,
    isPwd: true
  }),
  computed: {
    isMobile() {
      return this.$q.screen.lt.md
    },
    tenantInfo() {
      return this.$store.getters.getTenantInfo()
    },
    fallbackUrl() {
      if (this.$route.query.fallbackUrl)
        return decodeURIComponent(this.$route.query.fallbackUrl)
      else return '/'
    }
  },
  methods: {
    async validateNumber() {
      this.phoneNumber = this.phoneNumber.toEnglishNumber();
      if (this.$refs.phoneNumber.validate()) {
        const checkUser = await this.$api.identity.account.checkUser(this.phoneNumber)
        if (!checkUser.data.result) {
          await this.$router.replace(`/register?phoneNumber=${this.phoneNumber}&fallbackUrl=${encodeURIComponent(this.fallbackUrl)}`)
        } else this.page = 2
      }
    },
    async chargeAccount() {
      const res = await this.$axios.post(`${process.env.API_URL}/Payment/payment/preInvoice`, {
        amount: this.inputPrice,
        requestId: 0,
        code: "ChargeAccount"
      })
      const hash = res.data.result.hash
      await this.$router.push('/payment/' + hash)
    },
    changePage() {
      if (this.page === 1)
        this.$router.replace('/')
      else if (this.page === 2)
        this.page = 1
      else this.page = 2
    },
    async changePasswordType() {
      if (this.page === 2) {
        this.isLoading = true
        this.password = null
        if (this.timerMinutes <= 0 && this.timerSeconds <= 0) {
          this.timerMinutes = 1
          this.timerSeconds = 60
          await this.$api.identity.account.sendCode(this.phoneNumber)
          this.countDown()
          if (this.$q.platform.is.cordova) {
            smsRetriever.startWatch(
              (msg) => {
                let match = msg.match(/\d{5}/)
                if (match) {
                  this.verificationCode = match[0]
                  this.login()
                }
              })
          }
        }
        this.isLoading = false
        this.page = 3

      } else {
        this.verificationCode = null
        this.page = 2
      }
    },
    countDown() {
      if (this.timerSeconds > 0 && this.timerMinutes >= 0) {
        this.timerSeconds--
        setTimeout(() => {
          this.countDown()
        }, 1000)
      } else if (this.timerMinutes >= 0) {
        this.timerMinutes--
        this.timerSeconds = 60
        this.countDown()
      } else {
        this.timerSeconds = 60
        this.timerMinutes = 1
        this.isResendDisabled = false
        this.isTimerHidden = true
      }
    },
    async resendVerificationCode() {
      if (this.timerMinutes <= 0 && this.timerSeconds <= 0) {
        this.timerSeconds = 60
        this.timerMinutes = 1
        this.isResendDisabled = true
        this.isTimerHidden = false
        this.isLoading = true
        await this.$api.identity.account.sendCode(this.phoneNumber)
        this.isLoading = false
        this.countDown()
      }
    },
    async login() {
      this.isLoading = true
      try {
        const res = await this.$store.dispatch('login', {
          username: this.phoneNumber,
          password: this.password,
          verificationCode: this.verificationCode.toEnglishNumber()
        })
        if (res.success) {
          await this.$router.replace(this.fallbackUrl)
        } else {
          this.$q.notify({
            type: 'negative',
            message: 'نام کاربری یا کلمه عبور اشتباه است'
          })
        }
      } catch {
        this.$q.notify({
          type: 'negative',
          message: 'login_error'
        })
      }
      this.isLoading = false
    }
  },
  mounted() {
    useMeta(this.$store.state.meta)
    if (this.$route.query.phoneNumber) {
      this.phoneNumber = this.$route.query.phoneNumber
    }
    if (this.$route.query.step) {
      this.page = parseInt(this.$route.query.step)
    }
  },
}
</script>

<style>
h1 {
  font-size: 18px !important;
  line-height: 28px;
}

.info {
  color: #62666D;
  font-size: 11px;
}

.link {
  text-decoration: none;
  color: grey;
}
</style>
